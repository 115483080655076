var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c(_setup.Steps, { attrs: { "active-steps": 1 } }),
            _c(
              "el-button",
              {
                staticClass: "back-btn",
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _setup.toBack()
                  },
                },
              },
              [_vm._v(" 上一步 ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "f20 fw700 margin-b10" }, [
              _vm._v(" 设置权益 "),
            ]),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _setup.tableData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "date", label: "规格图片" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("img", {
                            staticStyle: { width: "50px" },
                            attrs: { src: row.imageIds, alt: "" },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "specValue", label: "规格" },
                }),
                !_setup.isNotSale
                  ? _c("el-table-column", {
                      attrs: { prop: "sellPrice", label: "销售价" },
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: { prop: "stockQuantity", label: "库存（件）" },
                }),
                _c("el-table-column", {
                  attrs: { label: "权益" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                type: row.isHasEquity ? "danger" : "primary",
                                underline: false,
                              },
                              on: {
                                click: function ($event) {
                                  return _setup.toSet(row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.isHasEquity ? "已设置" : "去设置"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "margin-t20" },
              [
                +_setup.query.goodStatus === 2
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _setup.onSubmit()
                          },
                        },
                      },
                      [_vm._v(" 保 存 ")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _setup.onSubmit("audit")
                          },
                        },
                      },
                      [_vm._v(" 提交审核 ")]
                    ),
              ],
              1
            ),
          ],
          1
        ),
        _c(_setup.EquityListDialog, {
          attrs: {
            show: _setup.equityListShow,
            "sku-id": _setup.skuId,
            "sku-type": _setup.query.type,
          },
          on: {
            hide: function ($event) {
              _setup.equityListShow = false
            },
            getList: _setup.getSkuDetail,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }