<template>
  <div class="app-container">
    <div class="outer">
      <div class="header">
        <Steps :active-steps="1" />
        <el-button
          class="back-btn"
          type="primary"
          size="small"
          @click="toBack()"
        >
          上一步
        </el-button>
      </div>

      <div>
        <div class="f20 fw700 margin-b10">
          设置权益
        </div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="date"
            label="规格图片"
          >
            <template slot-scope="{row}">
              <img
                :src="row.imageIds"
                alt=""
                style="width: 50px"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="specValue"
            label="规格"
          />
          <el-table-column
            v-if="!isNotSale"
            prop="sellPrice"
            label="销售价"
          />
          <el-table-column
            prop="stockQuantity"
            label="库存（件）"
          />
          <el-table-column
            label="权益"
          >
            <template slot-scope="{row}">
              <el-link
                :type="row.isHasEquity ? 'danger' : 'primary'"
                :underline="false"
                @click="toSet(row)"
              >
                {{ row.isHasEquity ? '已设置' : '去设置' }}
              </el-link>
            </template>
          </el-table-column>
        </el-table>

        <div
          class="margin-t20"
        >
          <el-button
            v-if="+query.goodStatus === 2"
            type="primary"
            size="small"
            @click="onSubmit()"
          >
            保 存
          </el-button>
          <el-button
            v-else
            type="primary"
            size="small"
            @click="onSubmit('audit')"
          >
            提交审核
          </el-button>
        </div>
      </div>

      <!-- 权益列表 -->
      <EquityListDialog
        :show="equityListShow"
        :sku-id="skuId"
        :sku-type="query.type"
        @hide="equityListShow = false"
        @getList="getSkuDetail"
      />
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue';
import Steps from '../components/steps';
import EquityListDialog from './EquityListDialog.vue';

const app = getCurrentInstance().proxy; // 获取全局 Vue 实例
const { query } = app.$route;

const isNotSale = ['2', '3'].includes(query.goodsBuyType);

const tableData = ref([]);
const equityListShow = ref(false);
const skuId = ref();

function toBack() {
  app.$router.replace({
    path: '/nb/goods/detail',
    query: {
      ...app.$route.query,
      type: 'edit',
    },
  });
}
// 获取商品详情
async function getSkuDetail() {
  const res = await app.$axios.get(app.$api.goodsmanage.trade_goods_showSpec, {
    params: {
      goodsId: query.goodsId,
    },
  });
  if (res.code === 0) {
    tableData.value = res.data.tradeGoodsSkuDtos;
  }
}
function toSet(row) {
  skuId.value = +row.id;
  equityListShow.value = true;
}
function onSubmit(type) {
  let setNames = tableData.value.filter((item) => !item.isHasEquity);
  if (setNames.length) {
    app.$message.error(`【${setNames.map((item) => item.specValue).join('-')}】请设置权益`);
    return;
  }

  if (type === 'audit') {
    app.$confirm('是否确认提交审核?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        const res = await app.$axios.post(app.$api.equity.toAudit, {
          goodId: app.$route.query.goodsId,
        });
        if (res.code !== 0) return;
        app.$message.success('操作成功！');
        app.$router.push('/nb/goods/index');
      })
      .catch(() => {});
  } else {
    app.$confirm('是否确认保存?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        app.$message.success('操作成功！');
        app.$router.push('/nb/goods/index');
      })
      .catch(() => {});
  }
}
onMounted(() => {
  getSkuDetail();
});
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  .back-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
